import GridCard from 'components/cards/gridCard'
import useReadBooks from 'hooks/useReadBooks'
import { useHistory } from 'react-router-dom'
import { getImage } from 'util/utils'
import Row from './row'
export interface ReadItAgianProps {
    
}
 
const ReadItAgian: React.FC<ReadItAgianProps> = (props:any) => {
    const {loading,error,value} = useReadBooks()
    const history = useHistory()
    if(value) return (
        <Row title="Read It Agian" style={props.style} className={props.className}>
            {value.map((obj) => (
                <GridCard 
                    primaryText={obj.title}
                    secendaryText={obj.parentTitle}
                    imgStyle={{ width: 165, height: 165 }}
                    style={{ maxWidth: 165, maxHeight: 300, display: 'block' }}
                    src={getImage(obj.thumb, 165, 165)}
                    onClick={()=>history.push(`/library/${obj.ratingKey}`)}
                />
            ))}
           
        </Row>
    );

    return null
}
 
export default ReadItAgian;