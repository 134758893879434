// @ts-nocheck
import React from 'react'
import {makeStyles } from '@material-ui/core'
import RecentlyAdded from './new'
import { useHistory } from 'react-router'
import Collections from './collections'
import ReadItAgian from './readItAgian'
const useStyles = makeStyles((theme)=>({
  item:{
    marginTop:'1.5rem',
    marginBottom:'1.5rem',
  },
  root:{
    marginTop:'1.5rem',

  }
})
)

export default function Home() {
  const classes = useStyles()
  const history = useHistory()
  
  return (
    <div className={classes.root}>
      <RecentlyAdded className={classes.item}/>
      <Collections className={classes.item}/>
      <ReadItAgian className={classes.item}/>
    </div>
  )
}
