import {
  Avatar,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
} from '@material-ui/core'
import { CloseRounded, ColorizeRounded } from '@material-ui/icons'

function Settings({ open, onClose }) {
  return (
    <Dialog open={open} maxWidth={150}>
      <DialogContent style={{ padding: '0' }}>
        <IconButton children={<CloseRounded />} onClick={onClose} />
        <List>
          <Profile />
          <Divider />
          <ListItem button>
            <ListItemAvatar children={<ColorizeRounded />} />
            <ListItemText children="Log Out" />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  )
}

function Profile() {
  const user = JSON.parse(localStorage.user)

  return (
    <ListItem>
      <ListItemAvatar children={<Avatar src={user.thumb}></Avatar>} />
      <ListItemText>
        {user.username}
        <Typography
          variant="body2"
          children={user.email}
          color="textSecondary"
        />
      </ListItemText>
    </ListItem>
  )
}

export default Settings
