import {
  Avatar,
  ButtonBase,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core'
import SearchBar from 'material-ui-search-bar/lib/components/SearchBar/SearchBar'
import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import Settings from './settings'

const useStyles = makeStyles({
  root: {
    width: 'unset',
    paddingTop: '10px',
    display: 'flex',
  },
  search: {
    width: 'auto',
  },
})
const Topbar = () => {
  const [dialog, setDialog] = useState(null)
  const styles = useStyles()
  const user = JSON.parse(localStorage.user)

  const avatarClick = () => {
    setDialog(false)
  }

  return (
    <>
      <Container className={styles.root}>
        <Grid container spacing={3} direction="row">
          <Grid item xs>
            <SearchBar
              className={styles.search}
              onChange={() => console.log('onChange')}
              onRequestSearch={() => console.log('onRequestSearch')}
            />
          </Grid>
          <Grid item >
            <Avatar
              src={user.thumb}
              component={ButtonBase}
              onClick={() => setDialog(true)}
            />
          </Grid>
        </Grid>
      </Container>
      <Settings open={dialog} onClose={() => setDialog(false)} />
    </>
  )
}
export default function (props) {
  return (
    <div {...props}>
      <Route
        component={Topbar}
        path={['/',]}
        exact
      />
    </div>
  )
}
