function qString(obj) {
  const perams = new URLSearchParams();
  for (const [key, value] of Object.entries(obj)) {
    perams.append(key, value);
  }
  return '?' + perams.toString();
}


function getImage(path, width, height) {
  const qString = new URLSearchParams();
  qString.append('height', height);
  qString.append('width', width);
  qString.append('url', path);
  qString.append('X-Plex-Token', localStorage.token);
  qString.append('X-Plex-Client-Identifier', localStorage.uuid);
  return `${localStorage.server}/photo/:/transcode?${qString.toString()}`;
}

function makeLink(path) {
  const perams = new URLSearchParams();
  perams.append('X-Plex-Token', localStorage.token);
  perams.append('X-Plex-Client-Identifier', localStorage.uuid);
  return localStorage.server + RemoveQString(path) + '?' + perams.toString();
}


function RemoveQString(uri) {
  const regex = /(?=\?).*/g;
  const result = regex.exec(uri);
  if (result === null) return uri;
  return uri.replace(result[0], '');
}

export {qString, makeLink, getImage};
