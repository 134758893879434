/**
 * Copyright Cameron Dehning All Rights Reserved
 */
//@ts-nocheck
import { makeStyles, Grid } from "@material-ui/core";

import { useHookstate } from "@hookstate/core";
import { playerState } from "global/PLAYER";
import { makeLink } from "util/utils";
import { useAudio } from "react-use";
import React, { useEffect } from "react";
import regesterMediaSession from "./mediaSession.func";
import Slider from "./Slider.comp";
import Buttons from "./Buttons.comp";
const useStyles = makeStyles({
  root: {
    paddingTop: "5px",
    paddingRight: "20px",
    paddingLeft: "20px",
    paddingBottom: "50px",
    maxWidth: 400,
  },
  title: {
    fountWeight: "500px",
    margin: "0.5rem",
  },
});
export default function ({ style }) {
  const HookState = useHookstate(playerState);
  const { metadata, position } = HookState;
  const track = useHookstate(playerState.position.track);
  const { Chapter } = metadata.get();
  const playbackSpeed = useHookstate(playerState.speed);

  try {
    /*----Hooks and Shit----*/
    const styles = useStyles();
    const [loaded, setLoaded] = React.useState(false);
    const [audio, state, controls, ref] = useAudio({
      src: makeLink(metadata.Media[0].Part[0].key.get()) + `#t=,10}`,
      autoPlay: false,
      preload: false,
      onPause: () => HookState.playing.set(false),
      onPlaying: () => {
        if (!HookState.playing.get()) HookState.playing.set(true);
      },
      onTimeUpdate: ({ target }) => {
        const currentTime = Math.round(target.currentTime);
        syncChapters(currentTime);
      },
      onLoadedMetadata: () => {
        regesterMediaSession({
          title: metadata.title.get(),
          author: metadata.originalTitle.get(),
          img: metadata.thumb.get(),
        });
      },
    });

    useEffect(() => {
      if (ref.current) ref.current.playbackRate = playbackSpeed.get();
      console.log("Change playback speed");
    }, [playbackSpeed.value]);

    function syncChapters(currentTime) {
      // find index of the current chapter
      const index = Chapter.findIndex((val, i, arr) => {
        const nextVal = arr[i + 1];
        if (nextVal === undefined) return true; //Applys to last chapter as it dosent have a nextVal
        if (val <= currentTime && nextVal >= currentTime) return true; //return true if currentTime is between val & nextVal
      });
      // updates the position.track if it dosent equal the found index
      if (index !== track.get()) track.set(index);
    }

    /*----Render----*/
    if (!audio) return null;
    return (
      <Grid>
        {audio}
        <Grid item align="center" style={style}>
          <Grid alignItems="center" className={styles.root} justify="center">
            <Slider audio={ref.current} />
            <Buttons {...{ controls, state }} />
          </Grid>
        </Grid>
      </Grid>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
}

function phraseTime(time: number) {
  const data = new Date(time);
  const hours = data.getHours() - 18 ? `${data.getHours() - 18}:` : "";
  const min = () => {
    let val = data.getMinutes();
    if (val == 0) return "00";
    if (val <= 9) return "0" + val;
    return val;
  };
  const seconds = () => {
    let val = data.getSeconds();
    if (val == 0) return "00";
    if (val <= 9) return "0" + val;
    return val;
  };
  return hours + `${min()}:${seconds()}`;
}
