import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import '@fontsource/lexend-deca'
import TextEllipsis from 'react-text-ellipsis'
import { Grid, Typography } from '@material-ui/core'
import { ButtonBase } from '@material-ui/core'
import Image from 'material-ui-image'

const styles = {
  image: {
    borderRadius: 10,
    width: '20vw',
    minWidth: '100px',
    maxWidth: '175px',
  },
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxHeight: 200,
    gap: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  textDiv: {
    verticalAlign: 'top',
    textAlign: 'left',
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
  },
  author: {
    fontFamily: theme.typography.fontFamily,
    paddingTop: 6,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  image: {
    borderRadius: 10,
    width: '20vw',
    minWidth: '100px',
    maxWidth: '175px',
    minHeight: 100,
    maxHeight: 175,
    height: '20vw',
  },
}))


export default function BookCard({ id, title, author, thumbnail, listMode, aspectRatio, onClick }) {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  
  return (
    <Grid container onClick={()=>onClick(id)} style={listMode ? { maxWidth: 500 } : {}} component={ButtonBase}>
      
      <Grid item
        xs={listMode ? 3 : 12}
        children={
          <Image
            src={thumbnail}
            color=""
            imageStyle={{ width: '100%', height: '100%', borderRadius: 5 }}
            disableSpinner
            aspectRatio={aspectRatio}
          />
        }
      />



      <Grid
        item
        xs={listMode ? 9 : 12}
        style={{
          verticalAlign: 'top',
          textAlign: 'left',
          paddingLeft: listMode ? 8 : 0,
          height: '100%'
        }}
      >
        <TextEllipsis
          lines={2}
          children={title}
          ellipsisChars={'...'}
          tag="div"
          tagClass={classes.title}
        />

        <Typography
          style={{
            display: 'box',

          }}
          noWrap
          variant="subtitle2"
          color='textSecondary'
          children={author}
        />
      </Grid>
    </Grid>
  )
}
