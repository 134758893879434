import "./core/index.css";

import OfflineFallback from "./core/OfflineFallback";
import { View } from "react-native";
import {
  BookInfo,
  Home,
  Library,
  LibraryPicker,
  Login,
  Servers,
  Artists,
  Settings,
  Collections,
} from "./routes/main";
import { Topbar } from "./components/main";
import React, { FC } from "react";
import {
  Route,
  BrowserRouter as Router,
  useHistory,
  Switch,
} from "react-router-dom";
import MobilePlayer from "routes/player/player.route";

const App: FC = function () {
  return (
    <View style={{ maxWidth: "100vw" }}>
      {/* <audio controls src="/test.mp3#t=10" onEnded={(x)=>console.log(x)}/> */}
      <Router>
        <OfflineFallback fallback={<p>You are Offle</p>}>
          <Switch>
            <Route path="/setup/login" component={Login} />
            <Route path="/setup/servers" component={Servers} />
            <Route path="/setup/library-picker" component={LibraryPicker} />
            <div className="app hide-scrollbar">
              <Topbar
                styles="grid-area: header;"
                style={{ maxWidth: "100vw" }}
              />
              <div className="main">
                <MainView />
                <Route path="/library/:id" component={BookInfo} />
                <Route path="/author/:id" component={Artists} />
                <Route path="/settings" component={Settings} />
                <Route path="/collection/:id" component={Collections} />
              </div>
              <MobilePlayer />
            </div>
          </Switch>
        </OfflineFallback>
      </Router>
    </View>
  );
};
export default App;

function MainView() {
  const history = useHistory();


  
  return (
    <>
      <Route path={["/", "/player"]} exact>
          <Home />
      </Route>
      <Route path={["/library", "/collections", "/player"]} exact>
          <Library />
      </Route>
    </>
  );
}
