import React, { useEffect, useRef, useState } from "react";
import { View, Dimensions, Animated } from "react-native";
import SlidingUpPanel from "lib/rn-sliding-up-panel/SlidingUpPanel";
import { useTheme } from "@material-ui/core";
import { Body, PlayerImage } from "./player.comp";
import TopBar from "./components/topbar.comp";
import { useHistory, useLocation } from "react-router";
import Navbar from "./components/navbar.comp";
import {
  useFirstMountState,
  useLifecycles,
  useMount,
  useUpdateEffect,
  useWindowSize,
} from "react-use";

const styles: any = {
  container: {
    flex: 1,
    backgroundColor: "#f8f9fa",
    alignItems: "center",
    justifyContent: "center",
  },
  panel: {
    flex: 1,
    backgroundColor: "white",
    position: "relative",
  },
  panelHeader: {
    height: 180,
    backgroundColor: "#b197fc",
    justifyContent: "flex-end",
    padding: 24,
  },
  textHeader: {
    fontSize: 28,
    color: "#FFF",
  },
  icon: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -24,
    right: 18,
    width: 48,
    height: 48,
    zIndex: 1,
  },
  iconBg: {
    backgroundColor: "#2b8a3e",
    position: "absolute",
    top: -24,
    right: 18,
    width: 48,
    height: 48,
    borderRadius: 24,
    zIndex: 1,
  },
};

const BottomSheet = (props) => {
  const draggedValue = useRef(new Animated.Value(142)).current;
  const panel = useRef(null);
  const win = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  const draggableRange = { top: win.height, bottom: 142 };

  useEffect(() => {
    const isPlayerUrl = location.search.includes("player");

    if (isPlayerUrl) {
      panel.current.show(draggableRange.top);
    } else panel.current.hide();
  }, [location.search]);

  function handleMomentumDragEnd(position) {
    const open = position !== 0;
    if (open) {
      if(!location.search.includes("player")){
        history.push(location.pathname+"?player");
      }
    } else if (location.search.includes("player")) {
      history.goBack();
    }
  }

  return (
    <>
      <Navbar animation={draggedValue} range={draggableRange} />
      <View style={[styles.container]}>
        <SlidingUpPanel
          ref={panel}
          draggableRange={draggableRange}
          animatedValue={draggedValue}
          snappingPoints={[0]}
          height={draggableRange.top}
          friction={0.5}
          containerStyle={props.containerStyle}
          onMomentumDragEnd={handleMomentumDragEnd}
        >
          {(dragHandler) => (
            <Animated.View style={[styles.panel, props.containerStyle]}>
              <TopBar
                animation={draggedValue}
                range={draggableRange}
                minimizePlane={() => {
                  history.goBack()
                }}
              />
              <View {...dragHandler}>
                <PlayerImage animation={draggedValue} range={draggableRange} />
              </View>
              <Body animation={draggedValue} range={draggableRange} />
            </Animated.View>
          )}
        </SlidingUpPanel>
      </View>
    </>
  );
};

const WithTheme = (props) => {
  const theme = useTheme();
  const winSize = useWindowSize();
  return (
    <>
      <div style={{ width: "100%", height: 146 }} />
      <BottomSheet
        containerStyle={{
          backgroundColor: theme.palette.background.paper,
          "::-webkit-scrollbar": {},
        }}
        draggableRange={{ top: winSize.height, bottom: 142 }}
        {...props}
      />
    </>
  );
};

export default WithTheme;
