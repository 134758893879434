import { createState } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'

interface settingsInterface{
    player:{
        seekFowerdAmount:10|5|30
        seekBackAmount:10|5|30

    }
}

const defaultSettings:settingsInterface ={
    player:{
        seekFowerdAmount:30,
        seekBackAmount:10
    }
  }

const settings = createState(defaultSettings).attach(Persistence('settings'))

export default settings
