import axios from 'axios';
import {makeUseAxios} from 'axios-hooks';
const usePlex = makeUseAxios({
  axios: axios.create({
    baseURL: localStorage.server,
    headers: {
      'X-Plex-Token': localStorage.token,
      'X-Plex-Client-Identifier': localStorage.uuid,
    },
    transformResponse: axios.defaults.transformResponse.concat((data) => data.MediaContainer),
  }),
});
const useGetServers = makeUseAxios({
  axios: axios.create({
    baseURL: 'https://plex.tv/api/v2/resources?includeHttps=1&includeRelay=1',
    headers: {
      'X-Plex-Token': localStorage.token,
      'X-Plex-Client-Identifier': localStorage.uuid,
    },
    transformResponse: axios.defaults.transformResponse.concat((data) => data.filter((obj)=>obj.provides==='server'?true:false)),

  }),
});

const useGoogleBooks = makeUseAxios({
  axios: axios.create({
    baseURL: 'https://www.googleapis.com/books/v1/volumes?maxResults=1&q=',
    transformResponse: axios.defaults.transformResponse.concat((data) => data.items[0].volumeInfo),
  }),
});

const plexApi = axios.create({
  baseURL: localStorage.server,
  headers: {
    'X-Plex-Token': localStorage.token,
    'X-Plex-Client-Identifier': localStorage.uuid,
  },
  protocol: 'GET',
  transformResponse: axios.defaults.transformResponse.concat((data) => data.MediaContainer),

});

const plexMeta = axios.create({
  baseURL: localStorage.server,
  headers: {
    'X-Plex-Token': localStorage.token,
    'X-Plex-Client-Identifier': localStorage.uuid,
  },
  protocol: 'GET',
  transformResponse: axios.defaults.transformResponse.concat((data) => data.MediaContainer.Metadata),

});

export {useGetServers, useGoogleBooks, plexApi, plexMeta};
export default usePlex;

