import BookCard from '../../../components/bookCard'
import { Grid } from '@material-ui/core'
import { getImage } from '../../../util/utils'
import { useWindowSize } from 'react-use'
import Error from '../../../components/error'
import Loading from '../../../components/loading'
const styles = {
  root: {
    maxWidth: '700px',
    paddingLeft: 10,
    paddingTop: 5,
    paddingRight: 10,

  },
}

function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1)
}

function Library({ reverse, usePlex, aspectRatio, listMode, onClick }) {
  const [{ data, loading, error, response }, refetch] = usePlex
  const win = useWindowSize()
  return (
    <Grid item align="center" style={{ height: '100%' }}>
      {loading ? <Loading /> : null}
      {error ? <Error message={error.message} url={error.config.url} /> : null}
      {data
        ? (
          <Grid
            container
            direction={reverse ? listMode ? 'column-reverse' : 'row-reverse' : listMode ? 'column' : 'row'}
            spacing={2}
            style={{ paddingRight: 16, paddingLeft: 16, maxWidth: '100%' }}
          >
            {data.Metadata.map((obj) => (
              <Grid
                xs={listMode ? 12 : win.width < 500 ? 4 : win.width < 1000 ? 3 : win.width < 1800 ? 2 : 1}
                {...listMode ? { container: true, alignContent: 'center' } : { item: true, container: false }}
                style={listMode ? {
                  marginTop: 10, marginBottom: 10
                } : {}}

              >
                <BookCard
                  thumbnail={getImage(obj.thumb, 200, 200)}
                  id={obj.ratingKey}
                  title={capitalize(obj.title)}
                  author={obj.parentTitle ? obj.parentTitle : ""}
                  thumSize={{ x: 200, y: 200 }}
                  listMode={listMode}
                  aspectRatio={aspectRatio}
                  onClick={onClick}
                />
              </Grid>
            ))}
          </Grid>

        )
        : null}
    </Grid>
  )
}

export default Library