// olny render Children if the navater is online

import { useNetworkState } from "react-use"


const devOveride = true
const OfflineFallback = (props)=>{
    const { fallback, children } = props
    const isOnline = useNetworkState().online
    console.log(isOnline)
    if(devOveride) return children
    if(isOnline) return children
    return fallback
}

export default OfflineFallback