import React, {useMemo,useState,useEffect} from 'react'
import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core'
import {
  Home as HomeIcon,
  LibraryBooksRounded,
} from '@material-ui/icons'
import { useHistory,useLocation } from 'react-router-dom'
import {Animated} from 'react-native'

function useHook(){
  const pathname =useLocation().pathname
  const [state,setState]= useState(3)
  useEffect(()=>{
    if(pathname==="/") setState(0)
    if(pathname.startsWith("/library")) setState(1)
    if(pathname.startsWith("/collections")) setState(1)
    
  },[pathname])
  return state
}


const Navbar=({animation,range})=> {
  const value = useHook()
  const history = useHistory() //hun


  const bottom=animation.interpolate({
    inputRange: [range.bottom, range.top / 2],
    outputRange: [0, -56],
    extrapolate: 'clamp',
  })

  return (
    <Animated.View style={{position: 'fixed', width: '100vw', zIndex:1,bottom: bottom}}>
        <BottomNavigation value={value}>
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon />}
            onClick={()=>history.push("/")}
          />
          <BottomNavigationAction
            label="Library"
            icon={<LibraryBooksRounded />}
            onClick={()=>history.push("/library")}
          />
          
        </BottomNavigation>
    </Animated.View>
  )
}

export default function (props) {
  return (<Navbar {...props}/>)
}
