import { useHookstate } from "@hookstate/core";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { ArrowBackRounded, ArrowDownwardSharp, ExpandMoreRounded, ListRounded, MoreRounded, PauseRounded, PlayArrowRounded, SpeedRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { playerState } from 'global/PLAYER'
import { Animated } from "react-native";
import ChaptersButton from "./chapters.comp";

const useStyles = makeStyles((theme)=>({
    title:{
        fontSize: "1.075rem",
        fontFamily: `"Roboto", "Helvetica", "Arial","sans-serif"`,
        fontWeight: 500,
        lineHeight: 1.5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
    }
}))

const buttons={
    Minimise:(props: any) => (
        <IconButton children={<ExpandMoreRounded />} {...props} />
    ),
}

const TopBar = ({ animation, range, minimizePlane }) => {
    const topNav = animation.interpolate({
      inputRange: [range.bottom * 4, range.top],
      outputRange: [0, 48],
      extrapolate: 'clamp',
    })
    return (
      <Animated.View style={{ height: topNav }}>
        <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
          <Grid container style={{ display: 'flex', flexWrap: 'nowrap', width: '100vw' }}>
            <Grid item style={{ padding: 5 }}>
              <buttons.Minimise onClick={minimizePlane} />
            </Grid>
            <Grid item style={{ width: '100%' }} />
            <Grid item alignContent='flex-end' style={{ padding: 5, right: 0}}>
                <ChaptersButton/>
            </Grid>
          </Grid>
        </div>
      </Animated.View>
    )
  }
  




export default TopBar