import { List } from '@material-ui/core'
import {TopBar} from './components'
import Account from './account'


const SettingRoute = ()=>{
    return (
        <div>
            <TopBar/>
            <List>
                <Account/>
            </List>
        </div>
    )
}
export default SettingRoute