import axios from "axios";
import { useAsync } from "react-use";

type chapterMetadataType = Array<{
  url: string;
  duration: number;
}>;

function useBook(albumKey) {
  const x = useAsync(async () => {
    const query = axios.create({
      baseURL: localStorage.server,
      headers: {
        "X-Plex-Token": localStorage.token,
        "X-Plex-Client-Identifier": localStorage.uuid,
      },
    });

    const album = (
      await query(
        `/library/metadata/${albumKey}/children?asyncAugmentMetadata=1`
      )
    ).data.MediaContainer.Metadata[0];

    const track = (
      await query(
        `${album.key}?includeChapters=1&includeReviews=1&includeCollections=1`
      )
    ).data.MediaContainer.Metadata[0];

    
    // Pass Gernres and summary to track
    const grandParent = (await query(`/library/metadata/${albumKey}`)).data
      .MediaContainer.Metadata[0];
    try {
      track.Genre = grandParent.Genre.map((obj) => obj.tag);
    } catch {}
    try {
      track.summary = grandParent.summary;
    } catch {}


    //end time stamps
    track.Chapter = [0, ...track.Chapter.map(obj=>Math.round(obj.endTimeOffset/1000))]


    console.log(track);

    return track;
  });
  return x;
}

export default useBook;
