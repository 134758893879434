import { useHookstate } from "@hookstate/core";
import {
    Button,
  Dialog,
    DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Slider,
  Typography,
} from "@material-ui/core";
import { SpeedRounded } from "@material-ui/icons";
import { playerState } from "global/PLAYER";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles({
  controlButton: {
    width: 34,
    height: 34,
  },
  title: {
    fontWeight: 500,
    fontSize: "5rem",
    fontFamily: "Lexend Deca",
  },
  slider: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const Popup = ({ open, onClose }) => {
  const classes = useStyles();
  const speed = useHookstate(playerState.speed);
  return (
    <Dialog open={open} onBackdropClick={()=>onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>Playback Speed</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Typography
            variant="h2"
            align="center"
            className={classes.title}
            children={`${speed.get().toString().length!==3?speed.get():speed.get().toString()+"0"}x`}
          />
          <div className={classes.slider}>
            <Slider
              value={speed.get()}
              min={0.5}
              max={3}
              step={0.05}
            //   @ts-ignore
              onChange={(_, value) => speed.set(value)}
            />
          </div>
        </Grid>
      </DialogContent>
      <Divider/>
      <DialogActions>
          <Button children="Close" variant="contained" color="primary" onClick={()=>onClose()}/>
      </DialogActions>
    </Dialog>
  );
};
const SpeedButton = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        children={<SpeedRounded className={classes.controlButton} />}
      />
      <Popup open={open} onClose={() => setOpen(false)} />
    </>
  );
};
export default SpeedButton;
