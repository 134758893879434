import { Grid, LinearProgress } from "@material-ui/core"

const Loading =()=>{
    return (
        <Grid item alignContent='center' alignItems='center' xl>
            <LinearProgress color='primary'/>
        </Grid>
    )
}

export default Loading