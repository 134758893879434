import axios from 'axios'
function startUp(){
    updateServerUrl()
}



async function updateServerUrl(){
    if(!navigator.onLine) return
    let response = await axios({
        url:'https://plex.tv/api/v2/resources',
        params:{
            includeHttps:1,
            includeRelay:1
        },
        headers:{
            "X-Plex-Client-Identifier":localStorage.getItem('uuid'),
            "X-Plex-Token":localStorage.getItem('token')
        }
    })
    response=response.data.forEach(obj=>{
        if(obj.provides!="server") return
        if(obj.connections[1].uri==localStorage.getItem('server')) return
        if(obj.name==localStorage.getItem('serverId')){
            console.log('Updating Server IP')
            localStorage.setItem('server', obj.connections[1].uri)
            window.location.replace('/')
        }

    })
    
}
export default startUp