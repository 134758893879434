import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core/'
import { useHistory } from 'react-router-dom'
/**
 * @param {string} title
 * @param {string} link
 * @param {string} thumbnail
 * @returns React Component
 */
export default function ColectionCard(props) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <div onClick={() => history.push(props.link)} className={classes.root}>
      <img
        className={classes.image}
        src={props.thumbnail}
        onClick={() => console.log('onClick')}
      />

      <Typography component="h5" variant="h5" children={props.title} />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    margin: 5,
  },
  image: {
    borderRadius: '10px',
    width: '100%',
    minWidth: '100px',
    maxWidth: '175px',
  },
})
