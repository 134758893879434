import {
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getImage } from '../../util/utils'
import { useHistory } from 'react-router'
import axios from 'axios'
import GridCard from '../../components/cards/gridCard'
import Row from './row'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
  },
}))
function NewBooks(props) {
  const [state, setState] = useState([])
  const history = useHistory()
  const iconSize = 165
  useEffect(() => {
    const fetchData = async () => {
      const query = axios.create({
        baseURL: localStorage.server,
        headers: {
          'X-Plex-Token': localStorage.token,
          'X-Plex-Client-Identifier': localStorage.uuid,
        },
      })

      let responce = await query('/library/recentlyAdded')
      let filtered = responce.data.MediaContainer.Metadata.filter(
        (obj, index) => {
          if (index > 9) return false
          if (obj.librarySectionID == `${localStorage.libraryID}`) return true
          return false
        }
      )
      setState(filtered)
    }
    fetchData()
  }, [])
  if(state[0]) return (
    <Row title="Recently Added" style={props.style} className={props.className}>
        {state.map((obj) => (
            <GridCard
            src={getImage(obj.thumb, iconSize, iconSize)}
            imgStyle={{ width: iconSize, height: iconSize }}
            primaryText={obj.title}
            secendaryText={obj.parentTitle}
            style={{ maxWidth: iconSize, maxHeight: 300, display: 'block' }}
            onClick={()=>history.push(`/library/${obj.ratingKey}`)}
          />
        ))}
    </Row>
  )

  return null
}

async function apiRequest() {
  const query = axios.create({
    baseURL: localStorage.server,
    headers: {
      'X-Plex-Token': localStorage.token,
      'X-Plex-Client-Identifier': localStorage.uuid,
    },
  })

  let responce = await query('/library/recentlyAdded')
  let filtered = responce.data.MediaContainer.Metadata.filter((obj, index) => {
    if (index > 9) return false
    if (obj.librarySectionID == `${localStorage.libraryID}`) return true
    return false
  })
  return filtered
}
export default NewBooks
