import { Divider, Typography } from "@material-ui/core";
import React from "react";
import usePlex from "../../hooks/usePlex";
import GridCard from "../../components/cards/gridCard";
import { getImage } from "../../util/utils";
import { useHistory } from "react-router-dom";
import Row from "./row";

interface CollectionsRowProps {}
// @ts-ignore
const styles = {
  div: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    whiteSpace: "nowrap",
    gap: 10,
    paddingTop: 10,
  },
};

const CollectionsRow: React.FC<CollectionsRowProps> = (props: any) => {
  const [{ data, loading, error }, refetch] = usePlex(
    `/library/sections/${localStorage.libraryID}/collections?includeCollections=1&includeExternalMedia=1&includeAdvanced=1&includeMeta=1`
  );
  const history = useHistory();
  if (data)
    return (
      <Row title="Collections" style={props.style} className={props.className}>
        {data.Metadata.map((obj) => (
          <GridCard
            src={getImage(obj.thumb, 90, 135)}
            imgStyle={{ height: 150, width: 100 }}
            primaryText={obj.title}
            secendaryText=""
            style={{ maxWidth: 165, maxHeight: 300, display: "block" }}
            onClick={() => history.push(`/collection/${obj.ratingKey}`)}
          />
        ))}
      </Row>
    );
  return null;
};

export default CollectionsRow;
