import {
  Button, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core'
import { DnsRounded } from '@material-ui/icons'
import { Fragment } from 'react'
import { useHistory } from 'react-router'
import usePlex from '../../hooks/usePlex'
export default function () {
  let [{ data, loading, error, response }] = usePlex('/library/sections')
  let history = useHistory()

  console.log(data)
  const librarySlect = (obj) => {
    localStorage.setItem('libraryID', obj.key)
  }

  if (data !== undefined) {
    return (
      <Fragment>
        <DialogContent>
          <List>
            {data.Directory.map((obj, i) => (
              <ListItem button onClick={() =>localStorage.setItem('libraryID', obj.key)}>
                <ListItemIcon>
                  <DnsRounded />
                </ListItemIcon>
                <ListItemText primary={obj.title} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/')}
            children="Next"
          />
        </DialogActions>
      </Fragment>
    )
  } else {
    return <div>Loading...</div>
  }
}
