import { Fragment } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { DnsRounded } from '@material-ui/icons'
import { useHistory } from 'react-router'
import {useGetServers} from '../../hooks/usePlex'

export default function () {
  const [{ data, loading, error, response }] = useGetServers()
  let history = useHistory()

  console.log(loading)
  if (!loading) {
    return (
      <Fragment>
        {console.log(response)}
        <DialogContent>
          <List>
            {data.map((obj) => (
              <ListItem
                button
                onClick={() =>{
                  console.log(obj)
                  localStorage.setItem('serverId', obj.name)
                  localStorage.setItem('server', obj.connections[1].uri)
                  }
                }
              >
                <ListItemIcon>
                  <DnsRounded />
                </ListItemIcon>
                <ListItemText primary={obj.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/setup/library-picker')}
          >
            Next
          </Button>
        </DialogActions>
      </Fragment>
    )
  } else {
    return <div>Loading...</div>
  }
}
