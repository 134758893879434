import {plexApi} from "./usePlex";
import { useAsync } from "react-use";

function useBook(){
    const x = useAsync(async()=>{
        const collections = (await plexApi(`/library/sections/${localStorage.libraryID}/collections`)).data.Metadata

        // Find Read Books Key
        const readBooksKeys = collections.filter((obj)=>obj.title==="Read Books")[0].ratingKey
        
        // Get Read Books
        const readBooks = await plexApi(`/library/collections/${readBooksKeys}/children`)

        return readBooks.data.Metadata
    })
    return x
}
export default useBook