import {
    Divider,
    Typography,
  } from '@material-ui/core'

  function Row(props) {
    return (
      <div style={props.style} className={props.className}>
        <div style={{paddingLeft:10,paddingRight:10}}>
          <Typography
            variant="button"
            children={props.title}
            style={{ fontSize: '1.2rem',  }}
          />
          <Divider />
        </div>

        <div
        className="hide-scrollbar"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            gap: 10,
            paddingTop: 10,
            paddingLeft:10,
            paddingRight: 10,
          }}
        >
          {props.children}
        </div>
      </div>
    )
  }
  export default Row
  