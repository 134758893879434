import { Grid, IconButton } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { useHistory } from "react-router-dom"


const TopBar = ()=>{
    const history = useHistory()
    return (
        <Grid container style={{width:'100vw'}}>
            <Grid item>
                <IconButton children={<ArrowBack/>} onClick={() =>history.goBack()}/>
            </Grid>
        </Grid>
    )
}

export {TopBar}