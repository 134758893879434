/**
 * Copyright Cameron Dehning All Rights Reserved
 *
 * Todo: switch from axios to fetch becose axios dosent like CROS
 */
import React, { FC } from 'react'
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,Dialog, Grid
} from '@material-ui/core'
import axios from 'axios'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import * as manifest from '../../manifest.json'

const Login: FC = () => {
  const styles = useStyles()
  let [password, setPassword] = React.useState('')
  let [email, setEmail] = React.useState('')

  let history = useHistory()

  function onClick() {
      axios('https://plex.tv/users/sign_in.json', {
        method: 'POST',
        auth: {
          username: email, //bedsteler2.0@gmail.com
          password: password, //45Fk&LQSAPYY/p8<
        },
        headers: {
          'X-Plex-Client-Identifier':localStorage.getItem('uuid'),
          ...manifest.plex
        }
      }).then((res) => {
        console.log(res)
        localStorage.setItem('user', JSON.stringify(res.data.user))
        localStorage.setItem('token', res.data.user.authToken)
        cacheProfilePic(res.data.user.thumb)
        history.push('/setup/servers')
      }).catch((e)=>{
        if(e.response.status===401){
           window.alert("Wrong Email or Password")
        }else{
          window.alert(e)
        }
      })

    
  }

  return (
    <Dialog open fullScreen>
      <DialogContent>

        <Grid container>
          <TextField
            type="email"
            className={styles.item}
            variant="filled"
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <TextField
            type="password"
            className={styles.item}
            variant="filled"
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          children="Login"
        />
      </DialogActions>
    </Dialog>
  )
}
const useStyles = makeStyles({
  root: {
    display: 'grid',
    'grid-column-gap': '10px',
    'grid-column': '1',
  },
  item: {
    'max-width': '500px',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'margin-top': '10px',
  },
})
export default Login

async function cacheProfilePic(url: string) {}
