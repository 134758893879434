import { Tabs, Tab, Grid, IconButton, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import PullRefresh from "../../components/PullRefresh";
import usePlex from "../../hooks/usePlex";
import Library from "./views/library";
import ListIcon from "@material-ui/icons/List";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
interface mainProps {}

const Main: React.FC<mainProps> = () => {
  const [reverse, setReverse] = useState(false);
  const [listMode, setListMode] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const books = usePlex(
    `/library/sections/${localStorage.libraryID}/search?type=9`
  );
  const collections = usePlex(
    `/library/sections/${localStorage.libraryID}/collections?includeCollections=1&includeExternalMedia=1&includeAdvanced=1&includeMeta=1`
  );

  const [index, setIndex] = useState(location.pathname === "/library" ? 0 : 1);

  const tabProps = (index) => ({
    id: `library-tab-${index}`,
    "aria-controls": `library-tabpanel-${index}`,
  });

  function handleChange(value) {
    if (value === 0) {
      history.push("/library");
      setIndex(0);
    }
    if (value === 1) {
      history.push("/collections");
      setIndex(1);
    }
  }

  return (
    <PullRefresh onRefresh={index === 0 ? books[1] : collections[1]}>
      {/* Header Tabbar */}
      <div>
        {/* @ts-ignore */}
        <Tabs
          value={index}
          onChange={(event, value) => handleChange(value)}
          centered
        >
          <Tab label="Books" {...tabProps(0)}/>
          <Tab label="Collections" {...tabProps(1)} />
        </Tabs>
      </div>

      {/* Library */}
      <Route path="/library">
        <Grid container style={{ width: "100%" }} wrap="nowrap">
          <div style={{ width: "100%" }} />
          <div style={{ display: "flex" }}>
            <IconButton
              children={<ListIcon />}
              // @ts-ignore
              color={listMode ? "primary" : "textSecondary"}
              onClick={() => setListMode(true)}
            />
            {/* @ts-ignore */}
            <IconButton
              children={<ViewComfyIcon />}
              // @ts-ignore
              color={listMode ? "textSecondary" : "primary"}
              onClick={() => setListMode(false)}
            />
          </div>
        </Grid>
        <Divider
          style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}
        />

        <Library
          reverse={reverse}
          usePlex={books}
          aspectRatio={1 / 1}
          listMode={listMode}
          onClick={(id) => history.push(`/library/${id}`)}
        />
      </Route>

      <Route path="/collections" exact>
        {/* Collections */}
        <div style={{width: "100%",height: 8, backgroundColor:'inherit'}}/>
        <Library
          reverse={reverse}
          usePlex={collections}
          aspectRatio={2 / 3}
          listMode={false}
          onClick={(id) => history.push(`/collection/${id}`)}
        />
      </Route>
    </PullRefresh>
  );
};

export default Main;
