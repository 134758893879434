import { Typography, makeStyles } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error';
const useStyles = makeStyles((theme)=>({
  icon: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    minHeight: 100,
    width: '30vw',
    hight: '30vw',
    color:theme.palette.text.secondary
  },
  root:{
    paddingTop:'50%'
  }
}))
function ErrorHandeler({ message, url }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <ErrorIcon className={classes.icon} color="textSecondary"/>
        <Typography variant="h4" color="textSecondary">{message}</Typography>
      </div>
      
    </div>
  )
}



export default ErrorHandeler
