import React from 'react';
import App from '../App';
import {AppRegistry} from 'react-native';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from '../themes/darkPink.json';
import {v4 as uuidv4} from 'uuid';
import * as serviceWorker from './serviceWorker';
import Metadata from '../functions/scrapeMetadata';
import {
  Provider as keepAliveProvider,
} from 'react-keep-alive';
import startUp from './startup/main';
const theme = createMuiTheme(Theme);
window.metadat=Metadata;
if ('uuid' in localStorage === false) {
  console.log('Creating Application UUID');
  localStorage.setItem('uuid', uuidv4());
}
if (
  'token' in localStorage === false &&
  window.location.pathname !== '/setup/login'
) {
  console.error('User Not Logged In Redirecting to /setup/login');
  window.location.replace('/setup/login');
}
startUp();

const AppProviders = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <keepAliveProvider>
          <CssBaseline />
          <App />
        </keepAliveProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
AppRegistry.registerComponent('App', () => AppProviders);
AppRegistry.runApplication('App', {rootTag: document.getElementById('root')});

serviceWorker.register();

// Bind Globals
window.MUI_THEME = theme;
