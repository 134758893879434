import usePlex from "hooks/usePlex";



function useArtists(id:string) {
  const _Result = usePlex(`/library/metadata/${id}/children`);

  return _Result;
}
export default useArtists;
