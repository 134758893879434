import { Typography, useTheme } from '@material-ui/core'
import { useWindowSize } from 'react-use'
import {Animated} from 'react-native'
const Title = ({ title, chapter, }:{title: string; chapter:string}) => {
  const { width } = useWindowSize()
  const padding = width / 16
  const theme = useTheme()

  

  return (
    <Animated.View style={{ paddingLeft: padding*1.75, paddingRight: padding*1.75,   }}>
      <Typography
        children={title}
        align="center"
        style={{
          
          paddingTop: width / 32,
          fontSize: '1.5rem',
          fontWeight: 550,
          lineHeight: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp:2
        }}
      />
      <Typography
        children={chapter}
        align="center"
        style={{
          width: '100%',
          marginTop: '0.2rem',
          fontSize: '1.1rem',
          fontWeight: 450,
          color: theme.palette.text.secondary,
        }}
      />
    </Animated.View>
  )
}
export default Title
