import {IconButton} from '@material-ui/core'
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import { useState } from 'react';
import axios from 'axios';
const MarkAsReadButton =({colections,id})=>{
    if(colections===undefined) colections = [{tag:'null'}]
    const tagNames = colections.map((obj)=>obj.tag)
    let [isRead, setRead] = useState( tagNames.includes("Read Books"))
    
    const MarkAsUnRead = ()=>{
        setRead(false)
        axios(`/library/metadata/${id}`,{
            method:'PUT',
            baseURL: localStorage.server,
            headers:{
                'X-Plex-Client-Identifier': localStorage.uuid,
                'X-Plex-Token': localStorage.token
            },
            params:{
               "collection[].tag.tag-":"Read Books"
            }
        })
    }

    const MarkAsRead = ()=>{
        setRead(true)
        axios(`/library/metadata/${id}`,{
            method:'PUT',
            baseURL: localStorage.server,
            headers:{
                'X-Plex-Client-Identifier': localStorage.uuid,
                'X-Plex-Token': localStorage.token
            },
            params:{
               "collection[42069].tag.tag":"Read Books"
            }
        })
    }

    return <IconButton onClick={isRead ? MarkAsUnRead:MarkAsRead}>
        {isRead? <BookmarkRoundedIcon/>:<BookmarkBorderRoundedIcon/>}
    </IconButton>
}

export default MarkAsReadButton