import { getImage } from "util/utils";

const regesterMediaSession = ({title,author,img})=>{
    if ("mediaSession" in navigator) {
        // @ts-ignore
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: title,
          artist: author,
          artwork: [
            {
              src: getImage(img, 300, 300),
              type: "image/png",
            },
          ],
        });
      }
}
export default regesterMediaSession