import { useState } from "react"
import {List, ListItemText,Button, Dialog, DialogActions, DialogTitle, Divider, ListItem, ListSubheader, Typography, ListItemIcon } from "@material-ui/core"
import { LibraryBooks, ExitToApp, SettingsEthernet } from "@material-ui/icons"
import { useHistory } from "react-router-dom"

const ConfermItem = ({lable, subLable, confermText, onConfirm,icon})=>{
    const [open, setOpen] = useState(false)
    return (<>
        <ListItem button onClick={()=>setOpen(true)}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                <Typography variant="h6" children={lable}/>
                <Typography variant="body1" children={subLable} color="textSecondary"/>
            </ListItemText>
        </ListItem>
        <Dialog open={open} onBackdropClick={()=>setOpen(false)}>
            <DialogTitle children={confermText}/>
            
            <DialogActions>
                <Button 
                 children="No"
                 color="primary"
                 variant='outlined'
                 onClick={()=>setOpen(false)}
                />
                <Button
                 children="Yes"
                 color="primary"
                 variant='contained'
                 onClick={onConfirm}
                />
            </DialogActions>
        </Dialog>
        </>
    )
}






export default ()=>{
    const history = useHistory()
    return (
        <>
        <ListSubheader>
            <Typography variant="h6" children='Account'/>
        </ListSubheader>
        <Divider/>

        <List>
            <ConfermItem 
                lable='Log Out'
                subLable='Sign Out of the Current Plex Account'
                confermText="Are you sure you want to log out"
                onConfirm={()=>history.push('/setup/login')}
                icon={<ExitToApp/>}
            />
            <ConfermItem 
                lable='Change Server'
                subLable='Switch plex server to scan'
                confermText="Are you sure you want to change Servers"
                onConfirm={()=>history.push('/setup/servers')}
                icon={<SettingsEthernet/>}
            />
            <ConfermItem 
                icon={<LibraryBooks/>}
                lable='Change Library'
                subLable='Change the Plex library to scan'
                confermText="Are you sure you want to change Librarys"
                onConfirm={()=>history.push('/setup/library-picker')}
            />
        </List>
        </>
    )
}