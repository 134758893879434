import React from 'react'
import { IconButton } from '@material-ui/core'
import { ArrowBackRounded as BackIcon } from '@material-ui/icons'
import { useHistory } from 'react-router'

export default function BackButton() {
  const history = useHistory()
  return (
        <IconButton onClick={() => history.goBack()}>
          <BackIcon />
        </IconButton>
  )
}
