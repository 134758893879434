import classes from "*.module.css";
import { useHookstate } from "@hookstate/core";
import {
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  List,
  makeStyles,
  ListItem,
  Typography,
  Divider,
  Box,
  ListItemAvatar,
  useTheme,
} from "@material-ui/core";
import {
  ExpandMoreRounded,
  ListRounded,
  ArrowBackRounded,
  PauseRounded,
  PlayArrowRounded,
  PauseOutlined,
  PlayArrowOutlined,
} from "@material-ui/icons";
import { playerState } from "global/PLAYER";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.075rem",
    fontFamily: `"Roboto", "Helvetica", "Arial","sans-serif"`,
    fontWeight: 500,
    lineHeight: 1.5,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  circleActive: {
    width: "0.7rem",
    height: "0.7rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    top: 0,
  },
  circle: {
    width: "0.7rem",
    height: "0.7rem",
    borderRadius: "50%",
    top: 0,
  },
}));

const buttons = {
  Chapters: (props) => <IconButton children={<ListRounded />} {...props} />,
  Back: (props) => <IconButton children={<ArrowBackRounded />} {...props} />,
  Pause: (props) => {
    const playing = useHookstate(playerState.playing);
    return (
      <IconButton
        children={playing.get() ? <PauseRounded /> : <PlayArrowOutlined />}
        {...props}
        onClick={() => {
          const audio = document.getElementsByTagName("audio")[0];
          if (playing.get()) audio.pause();
          if (!playing.get()) audio.play();
        }}
      />
    );
  },
};

const ChapterListItem = (props) => {
  const { index, title, time } = props;
  const classes = useStyles();
  const theme = useTheme();
  const track = useHookstate(playerState.position.track);
  if (isNaN(time)) return null;
  const mins = Math.round(time / 60);

  return (
    <ListItem style={{ padding: "6px 16px" }} button {...props}>
      <div
        className={classes.circle}
        style={{
          backgroundColor:
            track.get() === index ? theme.palette.primary.main : undefined,
        }}
      />
      <Box style={{ paddingLeft: 9 }}>
        <Typography
          variant="h6"
          children={title}
          color={track.get() === index ? "primary" : undefined}
        />
        <Typography
          variant="subtitle1"
          children={`${mins} mins`}
          color={track.get() === index ? "primary" : undefined}
        />
      </Box>
    </ListItem>
  );
};
const ChapterList = () => {
  // @ts-ignore
  const chapters = useHookstate(playerState.metadata.Chapter);
  const track = useHookstate(playerState.position.track);
  return (
    <List>
      {chapters.get().map((val, i, arr) => (
        <ChapterListItem
          title={`Chapter ${i + 1}`}
          index={i}
          time={arr[i + 1] - val}
          onClick={() => {
            const audio = document.getElementsByTagName("audio")[0];
            audio.currentTime = val + 1;
            audio.play();
            track.set(i);
          }}
        />
      ))}
    </List>
  );
};

const ChapterTopBar = ({ onClose }) => {
  const metadata = useHookstate(playerState.metadata);
  const classes = useStyles();
  return (
    <Grid
      container
      style={{ display: "flex", flexWrap: "nowrap", width: "100vw" }}
    >
      <Grid item style={{ padding: 5 }} xs={1}>
        <buttons.Back onClick={onClose} />
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: 10, maxWidth: "60%" }}>
        <p className={classes.title} children={metadata.title.get()} />
      </Grid>
      <Grid item style={{ width: "100%" }} />
      <Grid item alignContent="flex-end" style={{ padding: 5, right: 0 }}>
        <buttons.Pause />
      </Grid>
    </Grid>
  );
};

const ChaptersButton = ({}: any) => {
  const [open, setOpen] = useState(false);
  const [speedOpen, setSpeedOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  
  return (
    <>
      <buttons.Chapters onClick={() => history.push(location.pathname+"?"+"player&chapters")} />
      <Dialog open={location.search.includes("chapters")} onBackdropClick={() => setOpen(false)} fullScreen>
        <ChapterTopBar onClose={() => history.goBack()} />
        <Divider />
        <ChapterList />
      </Dialog>
    </>
  );
};
export default ChaptersButton;
