//@ts-nocheck
import { createState } from '@hookstate/core'
import { Persistence } from '@hookstate/persistence'
import {Metadata} from '../types/globle.metadata'

const metadata:Metadata ={
  title: '',
}

const playerState = createState({
  metadata: metadata,
  position: {
    time: 0,
    track: 0,
  },
  speed: 1,
  playing: false
}).attach(Persistence('player'))

export { playerState }
