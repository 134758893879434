import {
  makeStyles,
} from '@material-ui/core'
import useArtists from './useArtists.hook'
import {useHistory, useParams} from 'react-router-dom'
import Library from 'routes/library/views/library'
import PageTitle from 'components/PageTitle'

const useStyles = makeStyles((theme) => ({
  body:{
    paddingTop:"0.5rem"
  }
}))

function Artists() {
  const params:any = useParams()
  const books = useArtists(params.id)
  const history = useHistory()
  const classes = useStyles()
  return (
    <div>
      <PageTitle title={books[0].loading? "Loading...":books[0].data.parentTitle}/>
      <div className={classes.body}>
        <Library aspectRatio={1/1} usePlex={books} listMode={true} reverse={false} onClick={(id) => history.push(`/library/${id}`)}/>
      </div>
    </div>
  )
}

export default Artists
