// @ts-nocheck
import BackButton from "../../components/BackButton";
import { playerState } from "../../global/PLAYER";
import { useHookstate } from "@hookstate/core";
import { useHistory, useParams } from "react-router";
import {
  Typography,
  Button,
  makeStyles,
  Grid,
  Link,
  LinearProgress,
} from "@material-ui/core";
import { getImage, makeLink } from "../../util/utils";
import ReadButton from "./components/markAsRead";
import useBook from "hooks/useBook";
const useStyles = makeStyles((theme) => ({
  topBox: {
    maxWidth: "500px",
    display: "flex",
    maxHeight: 500,
  },
  image: {
    borderRadius: "20px",
    height:150,
    width:150
  },
  content: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  buttons: {
    marginTop: "10px",
  },
  textBox: {
    paddingLeft: 10,
  },
  title:{
    fontSize:'1.3rem',
    overflow: 'hidden',
    WebkitLineClamp:2,
    textOverflow:'ellipsis',
    display:'block',
    lineHeight: '1.3rem',
  }
}));

const BookInfo = () => {
  const styles = useStyles();
  const URLparams = useParams();
  let player = useHookstate(playerState);
  let history = useHistory();
  const { value, loading, error } = useBook(URLparams.id);
  
  function playHandler() {
    player.merge({
      metadata: value,
      position: {
        time: 0,
        track: 0,
      },
    });
  }
  console.log(value);
  if (error) return <p>Error</p>;
  return (
    <>
      {loading ? <LinearProgress/>:null}
      <Grid>
        <BackButton />
      </Grid>

      {loading?null:<div className={styles.content}>
        <div className={styles.topBox}>
          <img src={getImage(value.thumb,150,150 )} className={styles.image} loading="lazy"/>
          <div className={styles.textBox}>
            <Typography className={styles.title} variant="h6">{value.title}</Typography>
            <Typography
              variant="subtitle1"
              className={styles.link}
              children={<Link children={value.grandparentTitle} />}
              onClick={() =>
                history.push(`/author/${value.grandparentRatingKey}`)
              }
            />

            <ReadButton colections={value.Collection} id={URLparams.id} />
          </div>
        </div>

        <Grid container spacing={1} className={styles.buttons}>
          <Grid item xs>
            <Button
              onClick={playHandler}
              children="play"
              variant="contained"
              color="primary"
              fullWidth="true"
            />
          </Grid>
          <Grid item xs>
            <Button
              children="Download"
              variant="outlined"
              color="primary"
              fullWidth="true"
            />
          </Grid>
          {/* <MoreInfo author={value.parentTitle} title={value.title} /> */}
        </Grid>
      </div>}

     
    </>
  );
};

export default BookInfo;
