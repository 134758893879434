// @ts-nocheck
import { Slider } from "@material-ui/core"
import { playerState } from "global/PLAYER";
import { useHookstate } from "@hookstate/core";
import { useState } from "react";



const MySlider=({audio})=>{
  const Chapter=useHookstate(playerState.metadata.Chapter)
  const track=useHookstate(playerState.position.track)

  const start = Chapter[track.get()].get()
  const end = Chapter[track.get()+1].get()
  const [position,setPosition] = useState(start)
  function handleChange(_,progress){
    if(audio){
      audio.pause()
      setPosition(Math.round(progress))
    }
  }
  function handleChangeSubmit(){
    if(audio){
      audio.play()
      audio.currentTime=position
    }
  }
  if(audio) audio.addEventListener("timeupdate",({target})=>{
    const currentTime=Math.round(target.currentTime)
    if(currentTime!==position) setPosition(currentTime)
  })

  return <Slider onChangeCommitted={handleChangeSubmit} onChange={handleChange} value={position} min={start} max={end}/>
}
export default MySlider