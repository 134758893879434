import React, { CSSProperties, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import Image from "material-ui-image";
import { ButtonBase, Typography } from "@material-ui/core";
interface propsInterface {
  src: string;
  imgStyle: {
    height: number | string;
    width: number;
  };
  primaryText?: string;
  secendaryText?: string;
  style?: CSSProperties;
  onClick?:VoidFunction
}
const GridCard = (props: propsInterface) => {
  const { src, imgStyle, primaryText, secendaryText, style } = props;

  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  return (
      <ButtonBase style={{ ...style }} onClick={props.onClick}>
        <Image
          color="primary"
          loading={
            <Skeleton
              variant="rect"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          }
          src={src}
          style={imgStyle}
          imageStyle={{ borderRadius: '1rem' }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "0.4rem",
            paddingTop: "0.5rem",
          }}
        >
          <Typography
            style={{
              display: "block",
              lineHeight: 1,
              maxWidth: style.width,
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "1.06rem",
              // whiteSpace:"normal",
              fontFamily: "Lexend Deca",
            }}
            children={primaryText}
          />
          <Typography
            style={{
              display: "block",
              lineHeight: 1,
              maxWidth: style.width,
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="caption"
            color="textSecondary"
            children={secendaryText}
          />
        </div>
      </ButtonBase>
  );
};
export default GridCard;
