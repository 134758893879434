import { useHookstate } from '@hookstate/core'
import { playerState as HookState } from 'global/PLAYER'
import React from 'react'
import { Box, Grid, IconButton, Paper, Typography, useTheme } from '@material-ui/core'
import Image from 'material-ui-image'
import { getImage } from 'util/utils'
import { Animated } from 'react-native'
import Title from './components/title.comp'
import Player from './mp4-player/mp4Player.comp'
import { SpeedRounded } from '@material-ui/icons'
import { useWindowSize } from 'react-use'

const PlayerImage = ({ animation, range }) => {
  const theme = useTheme()
  const metadata = useHookstate(HookState.metadata)
  const position = useHookstate(HookState.position)
  const thumnanil = getImage(metadata.thumb.get(), 500, 500)
  const win = useWindowSize()


  const style = {
    image: {
      width: animation.interpolate({
        inputRange: [range.bottom, range.top],
        outputRange: [90, win.width * (1)], //Max with of im is 6/8 of the win hight
        extrapolate: 'clamp',
      }),

      paddingLeft: animation.interpolate({
        inputRange: [range.bottom, range.top],
        outputRange: [6, win.height * (1 / 16)],
        extrapolate: 'clamp',
      }),
      paddingRight: animation.interpolate({
        inputRange: [range.bottom, range.top],
        outputRange: [6, win.height / 16],
        extrapolate: 'clamp',
      }),
      paddingBottom: animation.interpolate({
        inputRange: [range.bottom, range.top],
        outputRange: [0, win.height * (1 / 32)],
        extrapolate: 'clamp',
      }),
    },

    miniplayer: {
      text: {
        fontWeight: 500,
        opacity: animation.interpolate({
          inputRange: [range.bottom, range.top / 4],
          outputRange: [1, 0],
          extrapolate: 'clamp',
        }),
      },
    },
  }
  const renderText = animation.interpolate({
    inputRange: [range.bottom, range.top],
    outputRange: [1, 0],
    extrapolate: 'clamp',
  })



  return (
    <Box flexDirection="row" display="flex" style={{ paddingTop: 6, paddingBottom: 6 }} >

      <Animated.View style={[style.image]}>
        <Paper elevation={3} style={{ borderRadius: "10%" }}>
          <Image src={thumnanil} imageStyle={{ borderRadius: '10%' }} color='' />
        </Paper>

      </Animated.View>
      {renderText ? (
        <Animated.View
          style={[style.miniplayer.text, { padding: 10, maxWidth: win.width * 0.75 }]}
          children={[
            <Typography
              children={metadata.title.get()}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 500,
                fontSize: '1.1rem',
                maxWidth: '100%'
              }}
            />,
            <Typography
              children={metadata.grandparentTitle.get()}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 400,
                fontSize: '0.95rem',
                color: theme.palette.text.secondary
              }}
            />,
          ]}
        />
      ) : null}
    </Box>


  )
}



const Body = ({ animation, range }) => {
  const title = useHookstate(HookState.metadata.title)
  const track = useHookstate(HookState.position.track)

  const fadeInOpacity = animation.interpolate({
    inputRange: [range.bottom, range.top],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  })

  return (
    <Animated.View style={{ opacity: fadeInOpacity }}>
      <Title
        title={title.get()}
        chapter={`Chapter ${track.get()+1}`}

      />
      <Player style={{ paddingTop: 10 }} />
    </Animated.View>
  )
}

export {Body, PlayerImage }
