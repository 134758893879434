import { useHookstate } from "@hookstate/core";
import { Grid, IconButton, Fab, makeStyles } from "@material-ui/core";
import {
  PlayArrowRounded,
  PauseRounded,
  Forward10Rounded,
  Forward30Rounded,
  Forward5Rounded,
  Replay10Rounded,
  Replay30Rounded,
  Replay5Rounded,

} from "@material-ui/icons";
import SETTINGS from "global/SETTINGS";
import React from "react";
import SpeedButton from "./SpeedButton.comp";

const useStyles = makeStyles({
    controlButton: {
      width: 34,
      height: 34,
    },
  });

const SeekBack = ({onClick})=>{
  const classes = useStyles()
  const pref = useHookstate(SETTINGS.player.seekBackAmount)
  return (
    <IconButton onClick={()=>onClick(pref.get())}>
      {pref.get()===5?<Replay5Rounded className={classes.controlButton}/>:null}
      {pref.get()===10?<Replay10Rounded className={classes.controlButton}/>:null}
      {pref.get()===30?<Replay30Rounded className={classes.controlButton}/>:null}
    </IconButton>
  )
}

const SeekFowerd = ({onClick})=>{
  const classes = useStyles()
  const pref = useHookstate(SETTINGS.player.seekFowerdAmount)
  return (
    <IconButton onClick={()=>onClick(pref.get())}>
      {pref.get()===5?<Forward5Rounded className={classes.controlButton}/>:null}
      {pref.get()===10?<Forward10Rounded className={classes.controlButton}/>:null}
      {pref.get()===30?<Forward30Rounded className={classes.controlButton}/>:null}
    </IconButton>
  )
}

const Buttons = ({controls, state}) => {
    const classes = useStyles()
  return (
    <Grid item>
      <SeekBack
        onClick={(amount) => controls.seek(state.time - amount)}
      />
      <Fab
        children={
          state.paused ? (
            <PlayArrowRounded className={classes.controlButton} />
          ) : (
            <PauseRounded className={classes.controlButton} />
          )
        }
        onClick={state.paused ? controls.play : controls.pause}
        color="primary"
      />
      <SeekFowerd
        onClick={(amount) => controls.seek(state.time + amount)}
      />
      <SpeedButton/>
    </Grid>
  );
};
export {SeekBack,SeekFowerd}
export default Buttons;
