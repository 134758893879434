import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useWindowSize } from 'react-use';
import ErrorHandeler from '../../components/error';
import Loading from '../../components/loading';
import usePlex from '../../hooks/usePlex';
import Library from '../library/views/library'
interface CollectionsProps {

}

function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1)
}

const Collections: React.FC<CollectionsProps> = () => {
    // @ts-ignore
    const { id } = useParams()
    const history = useHistory()
    const books = usePlex(`/library/collections/${id}/children`)
    const [{ data, loading, error }] = usePlex(`/library/collections/${id}`)
    const [children] = books
    const win = useWindowSize()
    const deviceType = (win.width / win.height < 12 / 16) //true == mobile

    if (loading) return <Loading />
    if (error) return <ErrorHandeler message={error.message} url={error.config.url} />
    if (children.loading) return <Loading />
    if (children.error) return <ErrorHandeler message={children.error.message} url={children.error.config.url} />

    if (data && children.data) {
        // Filter out all non audiobooks
        let filterData = children.data.Metadata.filter((obj) => {
            if (obj.type !== "album") return false;
            return true;
        })



        return (
            <div>

                <Library usePlex={books} listMode={deviceType} reverse={false} aspectRatio={1 / 1} onClick={(id) => history.push(`/library/${id}`)} />
            </div>
        );
    }

    return <p>Somthing has went tarably wrong</p>
};


export default Collections;






