import axios from 'axios'
//Axios Config
const Axios = axios.create({
  baseURL: localStorage.getItem('server'),
  headers: {
    'X-Plex-Token': localStorage.token,
    'X-Plex-Client-Identifier': localStorage.uuid,
  },
  params: {
    includePopularLeaves: 1,
    includeChapters: 1,
    includeReviews: 1,
    includePreferences: 1,
    includeConcerts: 1,
    includeExtras: 1,
  },
})
//Get book by title & author from google books
const Google = async (title: string, author: string) =>
  (
    await axios({
      url: 'https://www.googleapis.com/books/v1/volumes',
      params: {
        q: `search+inauthor:${author}+intitle:${title}`,
        maxResults: 1,
      },
    })
  ).data.items[0].volumeInfo

//--------------------------------------------------------------------------
function updateGenras(plexIn, google) {
  try {
    console.log(plexIn)
    const plex = plexIn.map((x) => x.tag)
    const removeDups = google.filter((str) => !plex.includes(str))
    const combine = plex.concat(removeDups)
    return combine
  } catch {
    return google
  }
}

//--------------------------------------------------------------------------

//Romoves Extra Author name
const splitAuthors = (i: string) => {
  try {
    return i
      .split(';')
      .map((str) => (str.startsWith(' ') ? str.slice(1, str.length) : str)) //Remove Space before name
  } catch {
    return i
  }
}

function updateAuthors(plexAuthors, googleAuthors) {
  const mainAuthor = plexAuthors[0]
  const combine = plexAuthors.concat(
    googleAuthors.filter((googleStr) => plexAuthors.includes(!googleStr))
  )
  const removeMain = combine.filter((str) => str != mainAuthor)
  return removeMain
}

//--------------------------------------------------------------------------

async function main(plexKeyURI: string) {
  const initalItems: any = (await Axios(plexKeyURI)).data.MediaContainer
    .Metadata[0]
  const plexAuthors = splitAuthors(initalItems.parentTitle)
  const gBook = await Google(initalItems.title, plexAuthors[0])

  console.log(initalItems)
  const plexLitTags = {
    coAuthors: updateAuthors(plexAuthors, gBook.authors),
    gernras: updateGenras(initalItems.Genre, gBook.categories),
  }
  console.log(plexLitTags)
  console.log(gBook)


  pushGenres(initalItems.ratingKey, plexLitTags.gernras)

}

async function pushGenres(key:string, genre:[]){
    console.log(key)
    let perams={}
    genre.forEach((val, i) => perams[`genre[${i}].tag.tag`]= val)
    Axios(`library/sections/${localStorage.libraryID}/all`, {params: {type:9,id:key, ...perams}, method:'PUT'})

}


export default main
