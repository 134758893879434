import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import BackButton from "./BackButton";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 20,
    paddingLeft: "0.75rem",
    display: "flex",
    alignItems: "center",
  },
}));

const PageTitle = ({ title }: { title: string }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <BackButton />
      <Typography className={classes.pageTitle} children={title} />
    </Grid>
  );
};
export default PageTitle;
